// polyfill
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
// @import packages
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
// @import components
import App from './App';
import routes from './config/routes';
import * as serviceWorker from './serviceWorker';
// @import styles
import 'scss/index.css';
// import './css/components/breadcrumbs/index.scss';
import store from './store';

ReactDOM.render(
	<CookiesProvider>
		<Provider store={store}>
			<Router>
				<App routes={routes} />
			</Router>
		</Provider>
	</CookiesProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
