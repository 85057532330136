import React from 'react';
// @import dependecies
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
// @import HOC
import Layout from 'js/HOC/Layout';
// @import styles
import 'scss/App.scss';

const loading = () => (
	<div className='animated fadeIn pt-3 text-center'>Loading...</div>
);

const App = props => {
	const { routes, location = { pathname: '/', key: '' } } = props;
	return (
		<React.Suspense fallback={loading()}>
			<Layout>
				<Switch location={location}>
					{routes.map((route, index) => {
						return (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								render={props => {
									return React.createElement(route.component, {
										...props
									});
								}}
							/>
						);
					})}
					<Redirect from='*' to='/' />
				</Switch>
			</Layout>
		</React.Suspense>
	);
};

export default withRouter(App);
