import axios from 'axios';
// @import actions
import store from 'store';
import { sendErrorToHOClocal } from 'actions';

export function axiosPost(endpoint, dataForm, token, callback) {
	axios
		.post(endpoint, dataForm, {
			headers: {
				Authtoken: token,
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(res => {
			callback(res.data);
		})
		.catch(function (error) {
			const errorDetails = `api endpoint: ${endpoint} , error: ${error}  , pagename: ${
				window && window.location
			}`;
			store.dispatch(sendErrorToHOClocal(errorDetails));
		});
}

export function axiosGet(endpoint, token, callback) {
	axios
		.get(endpoint, {
			headers: {
				Authtoken: token
			}
		})
		.then(res => {
			callback(res.data);
		})
		.catch(function (error) {
			const errorDetails = `api endpoint: ${endpoint} , error: ${error}  , pagename: ${
				window && window.location
			}`;
			store.dispatch(sendErrorToHOClocal(errorDetails));
		});
}
