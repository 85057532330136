import {
	SIGN_IN,
	SET_TOKEN,
	SEND_ERROR_TO_HOC,
	FETCH_INITIAL_DATA
} from 'actions/types';

const initialState = {
	token: null,
	userData: {},
	HOCerror: null
};

export const initialReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TOKEN:
			return {
				...state,
				token: action.payload
			};
		case SIGN_IN:
			return {
				...state,
				token: action.payload.auth.token,
				userData: action.payload.data && action.payload.data.user
			};
		case FETCH_INITIAL_DATA:
			return {
				...state,
				userData: action.payload.data && action.payload.data.user
			};
		case SEND_ERROR_TO_HOC:
			return {
				...state,
				HOCerror: action.payload
			};
		default:
			return {
				...state
			};
	}
};
