let host;
let domain = window.location.hostname;

switch (domain) {
	case 'localhost':
		host = 'localhost';
		break;
	case 'cms20.onderbouwdonline.site':
		host = 'demo';
		break;
	case 'cms20.kleuterwijzer.site':
		host = 'demo';
		break;
	case 'cms20.wijzneus.nl':
		host = 'production';
		break;
	case 'cms20.onderbouwdonline.nl':
		host = 'production';
		break;
	default:
		host = 'localhost';
}

export const host_name = host;
export const domain_url = domain;
