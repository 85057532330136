// @import utils
import { axiosGet } from 'js/utils/axiosCall';
// @import constants
import { screeninit_endpoint } from 'js/constants/endpoints';
// @import actions
import {
	SIGN_IN,
	SET_TOKEN,
	SEND_ERROR_TO_HOC,
	FETCH_INITIAL_DATA
} from 'actions/types';

export const setToken = token => dispatch => {
	dispatch({
		type: SET_TOKEN,
		payload: token
	});
};

const dispatchData = (type, data) => dispatch => {
	dispatch({
		type: type,
		payload: data
	});
};

export const logIn = inputData => dispatch => {
	dispatch({
		type: SIGN_IN,
		payload: inputData
	});
};

export const fetchInitialData = token => dispatch => {
	axiosGet(screeninit_endpoint, token, axiosResult => {
		if (axiosResult.status === 'success') {
			dispatch(dispatchData(FETCH_INITIAL_DATA, axiosResult));
		}
	});
};

export const sendErrorToHOC = error => dispatch => {
	dispatch({
		type: SEND_ERROR_TO_HOC,
		payload: error
	});
};

export function sendErrorToHOClocal(error) {
	return {
		type: SEND_ERROR_TO_HOC,
		payload: error
	};
}
