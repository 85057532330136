import onderbouwdLogo from 'assets/images/brand/logo_onderbouwdonline.png';
import kleuterwijzerLogo from 'assets/images/brand/logo_kleuterwijzer.png';
import wijzneusLogo from 'assets/images/brand/logo_wijzneus.png';
import onderbouwdFavicon from 'assets/images/brand/onderbouwd-favicon.ico';
import kleuterwijzerFavicon from 'assets/images/brand/kleuterwijzer-favicon.ico';

export const themeConfig = {
	onderbouwdonline: {
		name: 'onderbouwdonline',
		logo: onderbouwdLogo,
		favIcon: onderbouwdFavicon,
		siteTitle: 'Onderbouwd CMS 2020',
		manifestURL: 'manifest/onderbouwd/manifest.json'
	},
	kleuterwijzer: {
		name: 'kleuterwijzer',
		logo: kleuterwijzerLogo,
		favIcon: kleuterwijzerFavicon,
		siteTitle: 'Kleuterwijzer CMS',
		manifestURL: 'manifest/onderbouwd/kleuterwijzer.json'
	},
	wijzneus: {
		name: 'wijzneus',
		logo: wijzneusLogo,
		favIcon: 'wijzneus.ico',
		siteTitle: 'Wijzneus CMS',
		manifestURL: 'manifest/wijzneus/manifest.json'
	}
};
