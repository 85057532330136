let appname;
let api_url;
let cms19url;

// domain based settings
switch (window.location.hostname) {
	case 'localhost':
		// api_url = `https://api.${process.env.REACT_APP_SITE}.develop/`; // develop
		appname = process.env.REACT_APP_SITE;
		api_url = `https://api.${appname}.site/`; // nouman
		cms19url = `https://cms19.${appname}.develop`;
		break;
	case '192.168.1.11':
		// api_url = `https://api.${process.env.REACT_APP_SITE}.develop/`; // develop
		appname = process.env.REACT_APP_SITE;
		api_url = `https://api.${appname}.site/`; // nouman
		cms19url = `https://cms19.${appname}.develop`;
		break;
	case 'cms20.onderbouwd.develop':
		appname = 'onderbouwdonline';
		api_url = 'https://api.onderbouwd.develop/'; // develop
		cms19url = 'https://cms19.onderbouwd.develop';
		break;
	case 'cms20.onderbouwdonline.site':
		appname = 'onderbouwdonline';
		api_url = 'https://api.onderbouwdonline.site/'; // demo
		cms19url = 'https://cms19.onderbouwdonline.site/';
		break;
	case 'cms20.onderbouwdonline.nl':
		appname = 'onderbouwdonline';
		api_url = 'https://api.onderbouwdonline.nl/'; // prod
		cms19url = 'https://cms19.onderbouwdonline.nl/';
		break;
	case 'cms20.kleuterwijzer.site':
		appname = 'kleuterwijzer';
		api_url = 'https://api.kleuterwijzer.site/'; // demo
		cms19url = 'https://cms19.kleuterwijzer.site/';
		break;
	case 'cms20.kleuterwijzer.nl':
		appname = 'kleuterwijzer';
		api_url = 'https://api.kleuterwijzer.nl/'; // prod
		cms19url = 'https://cms19.kleuterwijzer.nl/';
		break;
	case 'cms20.wijzneus.site':
		appname = 'wijzneus';
		api_url = 'https://api.wijzneus.site/'; // demo
		cms19url = 'https://cms19.wijzneus.site/';
		break;
	case 'cms20.wijzneus.nl':
		appname = 'wijzneus';
		api_url = 'https://api.wijzneus.nl/'; // prod
		cms19url = 'https://cms19.wijzneus.nl/';
		break;
	default:
	// 	api_url = 'https://api.onderbouwdonline.nl/'; // prod
	// 	cms19url = 'https://cms19.onderbouwdonline.nl/';
}

export const app_name = appname;

export const cms19_url = cms19url;

//generic endpoints
export const edituser_endpoint = `${api_url}user/edit`;
export const error_endpoint = `${api_url}generic/error`;
export const signin_endpoint = `${api_url}auth/adminsignin`;
export const screeninit_endpoint = `${api_url}generic/screeninit`;
export const changepassword_endpoint = `${api_url}user/changepassword`;
export const accountprefill_endpoint = `${api_url}auth/activateprofileprefill`;
export const activateprofile_endpoint = `${api_url}auth/activateprofile`;
export const impersonate_endpoint = `${api_url}auth/impersonate`;

//academy endpoints
export const topicgettopics_endpoint = `${api_url}topic/gettopics`;
export const academycourseintro_endpoint = `${api_url}academy/course`;
export const academyaddcourse_endpoint = `${api_url}academy/addcourse`;
export const academymessage_endpoint = `${api_url}academy/messageadmin`;
export const academygetvouchers_endpoint = `${api_url}academy/vouchers`;
export const academyaddvoucher_endpoint = `${api_url}academy/addvoucher`;
export const academygetcourses_endpoint = `${api_url}academy/getcourses`;
export const academyaddchapter_endpoint = `${api_url}academy/addchapter`;
export const academyallchats_endpoint = `${api_url}academy/allchatsadmin`;
export const academycourseslist_endpoint = `${api_url}academy/courseslist`;
export const academygetchapters_endpoint = `${api_url}academy/getchapters`;
export const academydeletecourse_endpoint = `${api_url}academy/deletecourse`;
export const academyupdatecourse_endpoint = `${api_url}academy/updatecourse`;
export const academydeletevoucher_endpoint = `${api_url}academy/deletevoucher`;
export const academydeletechapter_endpoint = `${api_url}academy/deletechapter`;
export const academyupdatechapter_endpoint = `${api_url}academy/updatechapter`;
export const academyupdatevoucher_endpoint = `${api_url}academy/updatevoucher`;
export const academycoursesoverview_endpoint = `${api_url}academy/coursesoverview`;
export const academychapterdetails_endpoint = `${api_url}academy/coursechapteradmin`;
export const academyupdatecourseorderlist_endpoint = `${api_url}academy/updatecourseorderlist`;
export const academyupdatechapterorderlist_endpoint = `${api_url}academy/updatechapterorderlist`;
export const academydeletechapterattachment_endpoint = `${api_url}academy/deletechapterattachment`;
export const academydeletecourseattachment_endpoint = `${api_url}academy/deletecourseattachment`;
export const academypendingcoursecertificates_endpoint = `${api_url}academy/pendingcoursecertificates`;
export const academyupdatecoursecertifcatestatus_endpoint = `${api_url}academy/updatecoursecertifcatestatus`;
export const academymarkmessagesasread_endpoint = `${api_url}academy/markmessagesasread`;

// shop endpoints
export const shopadditem_endpoint = `${api_url}shopitem/add`;
export const shopupdateitem_endpoint = `${api_url}shopitem/update`;
export const shopoverview_endpoint = `${api_url}shopitem/adminlist`;
export const shopupdateitemorder_endpoint = `${api_url}shopitem/updateorder`;
export const shopupdateitemperiod_endpoint = `${api_url}shopitem/updateperiod`;

//faq endpoints
export const faqadd_endpoint = `${api_url}faq/add`;
export const faqlist_endpoint = `${api_url}faq/all`;
export const faqupdate_endpoint = `${api_url}faq/update`;
export const faqdelete_endpoint = `${api_url}faq/delete`;
export const faqcategories_endpoint = `${api_url}faq/categories`;
export const faqaddcategory_endpoint = `${api_url}faq/addcategory`;
export const faqupdatecategory_endpoint = `${api_url}faq/updatecategory`;
export const faqdeletecategory_endpoint = `${api_url}faq/deletecategory`;
export const faqlistbycategory_endpoint = `${api_url}faq/listbycategory`;
export const faqupdatefaqorderlist_endpoint = `${api_url}faq/updatefaqorderlist`;
export const faqupdatefaqcategoryorderlist_endpoint = `${api_url}faq/updatefaqcategoryorderlist`;

//assessment endpoints
export const assessmenttopics_endpoint = `${api_url}assessment/topics`;
export const assessmentaddtopic_endpoint = `${api_url}assessment/addtopic`;
export const assessmentupdatetopic_endpoint = `${api_url}assessment/updatetopic`;
export const assessmentdeletetopic_endpoint = `${api_url}assessment/deletetopic`;
export const assessmentchapters_endpoint = `${api_url}assessment/chapters`;
export const assessmentupdatetopicorderlist_endpoint = `${api_url}assessment/updatetopicorderlist`;

export const assessmentstatements_endpoint = `${api_url}assessment/statements`;
export const assessmentaddstatement_endpoint = `${api_url}assessment/addstatement`;
export const assessmentupdatestatement_endpoint = `${api_url}assessment/updatestatement`;
export const assessmentdeletestatement_endpoint = `${api_url}assessment/deletestatement`;
export const assessmentupdatestatementorderlist_endpoint = `${api_url}assessment/updatestatementorderlist`;

//admin management endpoints
export const adminlist_endpoint = `${api_url}admin/list`;
export const adminadd_endpoint = `${api_url}admin/add`;
export const adminedit_endpoint = `${api_url}admin/edit`;
export const admindelete_endpoint = `${api_url}admin/delete`;

//school management endpoints
export const schoollist_endpoint = `${api_url}school/list`;
export const schooladd_endpoint = `${api_url}school/add`;
export const schooledit_endpoint = `${api_url}school/edit`;
export const schoolstaff_endpoint = `${api_url}school/staff`;
export const schoolcopyexampledata_endpoint = `${api_url}school/copyexampledata`;
export const schooladdcoordinator_endpoint = `${api_url}school/addcoordinator`;

//invoice endpoints
export const invoiceadd_endpoint = `${api_url}invoice/add`;
export const invoicelist_endpoint = `${api_url}invoice/all`;
export const invoicearticlelist_endpoint = `${api_url}article/all`;
export const invoiceupdate_endpoint = `${api_url}invoice/update`;
export const invoicedelete_endpoint = `${api_url}invoice/delete`;
export const invoicegenerate_endpoint = `${api_url}invoice/generate`;
export const invoicecredit_endpoint = `${api_url}invoice/addcredit`;
export const invoicedashboard_endpoint = `${api_url}invoice/dashboard`;
export const invoicedownloadlist_endpoint = `${api_url}invoice/downloadlist`;
export const invoiceupdatestatus_endpoint = `${api_url}invoice/updatestatus`;
export const invoicelinedelete_endpoint = `${api_url}invoice/deleteinvoiceline`;
export const invoicesendreminder_endpoint = `${api_url}invoice/sendreminder`;
export const invoicegeneratereminder_endpoint = `${api_url}invoice/generatereminder`;
//invoice customer endpoints
export const customeradd_endpoint = `${api_url}customer/add`;
export const customerlist_endpoint = `${api_url}customer/all`;
export const customerupdate_endpoint = `${api_url}customer/update`;
export const customerdelete_endpoint = `${api_url}customer/delete`;
// invoice license list
export const annuallicenselist_endpoint = `${api_url}invoice/annuallicense`;
export const annuallicensecreate_endpoint = `${api_url}invoice/annuallicensecreate`;
// article endpoints
export const articlelist_endpoint = `${api_url}article/all`;
export const articleadd_endpoint = `${api_url}article/add`;
export const articlelupdate_endpoint = `${api_url}article/update`;
export const articledelete_endpoint = `${api_url}article/delete`;
// purchase endpoints
export const purchaselist_endpoint = `${api_url}purchase/list`;
export const purchaseadd_endpoint = `${api_url}purchase/add`;
export const purchaseupdate_endpoint = `${api_url}purchase/update`;
export const purchasedelete_endpoint = `${api_url}purchase/delete`;
export const purchasedeleteattachment_endpoint = `${api_url}purchase/deleteattachment`;
