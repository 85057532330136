import React, { Component, Fragment } from 'react';
// @import dependencies
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { withRouter, Redirect } from 'react-router-dom';
// @import utils
import { domain_url } from 'js/utils/getEnv';
// @import actions
import { fetchInitialData, setToken } from 'actions';
// @import constants
import { themeConfig } from 'themeConfig';
import { app_name } from 'js/constants/endpoints';

export class Layout extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const { cookies } = props;

		this.state = {
			Authtoken: cookies.get('authtokenCms') || '',
			redirect: false
		};
	}

	componentDidMount() {
		const { pathname } = this.props.location;
		if (pathname.includes('activateaccount')) {
			this.props.setToken(null);
		} else if (this.state.Authtoken) {
			this.props.setToken(this.state.Authtoken);
			this.props.fetchInitialData(this.state.Authtoken);
		} else {
			this.setState({
				redirect: true
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.token !== prevProps.token) {
			this.props.fetchInitialData(this.props.token);
			this.setState({
				redirect: this.props.token === null ? true : false
			});
			if (this.props.token === null) {
				const { cookies } = this.props;
				let expDate = new Date();
				expDate.setTime(expDate.getTime() - 24 * 60 * 60 * 1000);
				cookies.remove('authtokenCms', {
					domain: domain_url,
					expires: expDate,
					path: '/'
				});
			}
		}
	}

	render() {
		if (this.state.redirect && this.props.location.pathname !== '/login') {
			return <Redirect from='*' to='/login' />;
		}
		if (!this.state.redirect && this.props.location.pathname === '/login') {
			return <Redirect from='*' to='/' />;
		}
		return (
			<Fragment>
				<Helmet
					defaultTitle={themeConfig[app_name].siteTitle}
					titleTemplate={`%s | ${themeConfig[app_name].siteTitle}`}
				>
					<link
						rel='icon'
						type='image/png'
						href={themeConfig[app_name].favIcon}
						sizes='16x16'
					/>
					<link
						href='https://fonts.googleapis.com/css?family=Roboto:300,400|Schoolbell'
						rel='stylesheet'
					/>
					<script
						src='https://kit.fontawesome.com/766b927ef0.js'
						crossorigin='anonymous'
					></script>
				</Helmet>
				<div className='mainWrapper'>{this.props.children}</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	userData: state.initialData.userData
});

export default withRouter(
	withCookies(
		connect(mapStateToProps, {
			setToken,
			fetchInitialData
		})(Layout)
	)
);
