// @import dependecies
import React from 'react';

// Containers
const Login = React.lazy(() => import('js/containers/Login'));
const Page404 = React.lazy(() => import('js/containers/Page404'));
const DefaultLayout = React.lazy(() => import('js/containers/DefaultLayout'));
const ActivateAccount = React.lazy(() =>
	import('js/containers/users/ActivateAccount')
);

const routes = [
	{
		path: '/login',
		component: Login,
		name: 'Login Page',
		exact: true
	},
	{
		path: '/activateaccount/:userid/:hash',
		exact: true,
		name: 'Account aanmaken',
		component: ActivateAccount
	},
	{
		path: '/404',
		component: Page404,
		name: 'Page 404',
		exact: true
	},
	{
		path: '/',
		component: DefaultLayout,
		name: 'Home'
	}
];

export default routes;
